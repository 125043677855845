import React from "react";

const Header = () => {
  return (
    <header className={"flex flex-col px-3.5"}>
      <div
        className={
          "border-gray-200 border-b-2 flex align-middle pt-3.5 mx-10 mb-3.5"
        }
      >
        <img
          className={
            "mb-3.5 border-r-2 border-gray-200 pr-3.5 w-36 h-24 object-contain"
          }
          src={process.env.REACT_APP_COMPANY_LOGO}
          alt={process.env.REACT_APP_COMPANY_NAME}
        />
        <b className="self-center text-sm font-semibold text-[#737373] pl-3.5 mb-3">
          Submit Volunteer Hours
        </b>
      </div>
    </header>
  );
};

export default Header;
